import { Box, Typography } from '@mui/material';
import { BlockedIcon } from '@hugeicons/react';

export default function NoAccess() {
    return (
        <Box display="flex" alignItems="center" justifyContent="center" height="100%" sx={{ opacity: 0.7 }}>
            <BlockedIcon size={56} />
            <Typography variant="h4" sx={{ ml: 2, userSelect: 'none' }}>Access Restricted</Typography>
        </Box>
    );
}
