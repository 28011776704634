import { cancelData, saveData } from "../../Util/FormUtil";
import { Box, Button, CircularProgress } from "@mui/material";

export default function ActionButtons(props: any) {

    const [data, setData] = props.state ? props.state : [null, () => {}];

    return (
        <Box display="flex" justifyContent="flex-end" alignItems="center" className={"animbuttons" + ((data.modified || data.saving || props.active) ? " active" : "")}>
            <Button variant="outlined" sx={{ mr: 2, mt: 2 }} onClick={() => { cancelData(setData); }}>
                Cancel
            </Button>
            <Button variant="contained" color="primary" sx={{ mt: 2 }} 
                disabled={!data.modified} onClick={() => { saveData(setData, props.onSave); }}
            >
                {(!data.saving) ? "Save" : <CircularProgress size={24} sx={{ color: 'inherit' }} />}
            </Button> 
        </Box>
    );
}
