import { useState } from "react";
import { Box, Button, CircularProgress, IconButton, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import { ViewIcon, ViewOffSlashIcon } from "@hugeicons/react";
import { T } from "../../Util/Localize";
import Api from "../../Util/Api";

export default function Login(props: any) {

    const [credentials, setCredentials] = useState({ email: '', password: '' });
    const [showPassword, setShowPassword] = useState(false);
    const [authorizing, setAuthorizing] = useState(false);

    const handleLogin = async () => {
        if (authorizing) return;
        setAuthorizing(true);
        if (await Api.login(credentials.email, credentials.password)) {
            props.setIsAuthorized(true);
        }
        setAuthorizing(false);
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', width: '100%', height: '100vh', overflow: 'hidden', background: 'radial-gradient(#222, #000)' }}>
            <Paper elevation={1} sx={{ px: 5, py: 3, position: 'relative', width: 360, maxWidth: '80vw', zIndex: 10 }}>
                <Box display="flex" flexDirection="column" gap={2}>
                    <Typography variant="h5" sx={{ textAlign: 'center' }}>
                        {T("Account Login")}
                    </Typography>
                    <TextField 
                        type="email" 
                        label={T("Email Address")} 
                        size="small" 
                        disabled={authorizing}
                        onChange={(e) => setCredentials((prev) => ({ ...prev, email: e.target.value }))} 
                    />
                    <TextField 
                        type={showPassword ? "text" : "password"} 
                        label={T("Password")} 
                        size="small" 
                        disabled={authorizing}
                        onChange={(e) => setCredentials((prev) => ({ ...prev, password: e.target.value }))} 
                        slotProps={{
                            input: {
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() => setShowPassword(!showPassword)}
                                      edge="end"
                                    >
                                      {showPassword ? <ViewOffSlashIcon /> : <ViewIcon />}
                                    </IconButton>
                                  </InputAdornment>
                                )
                            }
                        }}
                        />
                    <Box display="flex" justifyContent="center">
                        <Button variant="contained" color="primary" sx={{ minWidth: '50%' }} onClick={handleLogin} disabled={credentials.email.length === 0 || credentials.password.length === 0}>
                            {authorizing ? <CircularProgress size={24} sx={{ color: 'inherit' }} /> : T("Login")}
                        </Button>
                    </Box>
                </Box>
            </Paper>
            <iframe 
                src="https://player.vimeo.com/video/1034026933?background=1&autoplay=1&loop=1&byline=0&title=0&portrait=0&player_id=0&app_id=58479" 
                allow="autoplay; fullscreen;" 
                style={{ border: 0, position: 'absolute', top: 'calc((100vh - max(100vh, 56vw)) / 2)', left: 'calc((100vw - max(100vw, 180vh)) / 2)', width: 'max(100vw, 180vh)', height: 'max(100vh, 56vw)' }}
                title="Login Background Video"
            />
        </div>
    );
}
