import { useEffect, useRef, useState } from "react";
import { AppBar, BottomNavigation, BottomNavigationAction, Box, CircularProgress, IconButton, SwipeableDrawer, Toolbar } from "@mui/material";
import { Link, Outlet, useLocation } from "react-router-dom";
import BreadcrumbsComponent from "./BreadcrumbsComponent";
import ProfileMenu from "./ProfileMenu";
import Api from "../Util/Api";
import Login from "../Pages/Login/Login";
import { SnackbarProvider } from 'notistack';
import AdminPanel from "../Pages/AdminPanel/AdminPanel";
import { DialogsProvider } from "@toolpad/core";
import { Menu01Icon, DashboardSquare03Icon, WebDesign01Icon, UserGroupIcon, UngroupLayersIcon } from "@hugeicons/react"; 
import { setBodyClass } from "../Util/Functions";
import NavPanel from "./NavPanel";

export default function LayoutComponent() {

    const loadStarted = useRef(false);
    const [isAuthorized, setIsAuthorized] = useState(-1);
    const [isMobile, setIsMobile] = useState(Api.isMobile());
    const [isMobileNav, setIsMobileNav] = useState(false);
    const location = useLocation();
    const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

    const loadSession = () => {
        if (loadStarted.current) { return; }
        loadStarted.current = true;
        Api.authorize().then((auth) => {
            if (auth) {
                setIsAuthorized(1);
            }
            else {
                setIsAuthorized(0);
            }
        });
    }

    useEffect(() => {
        loadSession();
    }, []);

    useEffect(() => {
        const resize = () => {
            var im = Api.isMobile();
            setBodyClass('mobile', im);
            setIsMobile(im);
        }
        window.addEventListener("resize", resize);
        return () => {
            window.removeEventListener("resize", resize);
        }
    }, []);

    useEffect(() => {
        setIsMobileNav(false);
    }, [location.pathname])

    return isAuthorized === -1 ? (
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100vh', width: '100vw', overflow: 'hidden' }}>
            <CircularProgress color="inherit" size={32} sx={{ opacity: 0.25 }} />
        </Box>
    ) : isAuthorized === 0 ? (
        <Login setIsAuthorized={setIsAuthorized} />
    ) : (
        <DialogsProvider>
            <SnackbarProvider maxSnack={3}>
                <Box display="flex" className="fullheight" bgcolor="var(--background-default-content, #ECEFF1)">
                    {(isMobile) ? (
                        <SwipeableDrawer 
                            anchor="left"
                            open={isMobileNav}
                            onOpen={() => { setIsMobileNav(true); }}
                            onClose={() => { setIsMobileNav(false); }}
                            disableBackdropTransition={!iOS} 
                            disableDiscovery={iOS}
                        >
                            <Box sx={{ width: 240, maxWidth: '80vw' }}>
                                <NavPanel />
                            </Box>
                        </SwipeableDrawer>
                    ) : (
                        <Box
                            display="flex"
                            flexDirection="column"
                            width={252}
                            bgcolor="var(--background-default, #FFF)"
                            borderRight="1px solid var(--elevation-outlined, #E0E0E0)"
                        >
                            <Box display="flex" alignItems="center" p={2}>
                                <img
                                    src="/art/HDPhotoHub_400.png"
                                    alt="Customer Logo"
                                    style={{ width: "100%", height: "auto" }}
                                />
                            </Box>
                            <Box flex={1} overflow={"hidden auto"} p={2} pt={1} className="nav-root">
                                <NavPanel />
                            </Box>
                        </Box>
                    )}
                    <Box flex={1} display="flex" flexDirection="column" sx={{ width: '100vw' }}>
                        {(isMobile) && (
                            <AppBar position="static">
                                <Toolbar disableGutters>
                                    <IconButton
                                        size="large"
                                        edge="start"
                                        color="inherit"
                                        aria-label="open drawer"
                                        sx={{ m: 0 }}
                                        onClick={() => { setIsMobileNav(true); }}
                                    >
                                        <Menu01Icon />
                                    </IconButton>
                                    <Box sx={{ background: 'url(/art/HDPhotoHub_400.png) 0% center no-repeat', backgroundSize: 'contain', width: '100%', height: '38px' }}></Box>
                                    <Box display="flex" alignItems="center" sx={{ mr: 1.5 }}>
                                        <ProfileMenu isMobile={isMobile} />
                                    </Box>
                                </Toolbar>
                            </AppBar>
                        )}
                        <Box flex={1} display="flex" flexDirection="column" position="relative" overflow="hidden">
                            <Box flex={1} display="flex" flexDirection="column" overflow={(isMobile ? "hidden scroll" : "hidden auto")}>
                                {(!isMobile) && (
                                    <Box p="var(--1, 8px) var(--3, 24px)">
                                        <Box display="flex" justifyContent="space-between">
                                            <Box display="flex" alignItems="center">
                                                <BreadcrumbsComponent />
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                <ProfileMenu />
                                            </Box>
                                        </Box>
                                    </Box>
                                )}
                                <Box flex={1} p={(isMobile ? "var(--2, 16px) calc(var(--2, 16px) - 11px) var(--2, 16px) var(--2, 16px)" : "var(--1, 8px)  var(--3, 24px) var(--3, 24px) var(--3, 24px)")}>
                                    <Outlet context={{ isMobile }} />
                                </Box>
                            </Box>
                        </Box>
                        {(isMobile) && (
                            <BottomNavigation value={location.pathname} showLabels>
                                <BottomNavigationAction 
                                    label="Dashboard" 
                                    icon={<DashboardSquare03Icon />} 
                                    component={Link}
                                    to="/ux/Dashboard"
                                />
                                <BottomNavigationAction 
                                    label="Sites" 
                                    icon={<WebDesign01Icon />} 
                                    component={Link}
                                    to="/ux/Sites"
                                />
                                <BottomNavigationAction 
                                    label="Clients" 
                                    icon={<UserGroupIcon />} 
                                    component={Link}
                                    to="/ux/Clients"
                                />
                                <BottomNavigationAction 
                                    label="Groups" 
                                    icon={<UngroupLayersIcon />} 
                                    component={Link}
                                    to="/ux/Groups"
                                />
                            </BottomNavigation>
                        )}
                    </Box>
                    {(!isMobile && Api.user.isAdmin) && (
                        <AdminPanel />
                    )}
                </Box>
            </SnackbarProvider>
        </DialogsProvider>
    );
};
