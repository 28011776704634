import './AdminPanel.css';
import { Box, Button, Chip, Tab, Tabs } from "@mui/material";
import { Wrench02Icon } from '@hugeicons/react';
import Api from "../../Util/Api";
import { useState } from "react";
import { clipboardSetText, setBodyClass } from '../../Util/Functions';
import AdminPanelAPI from './AdminPanelAPI';
import AdminPanelDatabase from './AdminPanelDatabase';
import AdminPanelEvents from './AdminPanelEvents';
import AdminPanelRequests from './AdminPanelRequests';
import AdminPanelLocalize from './AdminPanelLocalize';
import SwitchInput from '../../Components/input/SwitchInput';

export default function AdminPanel() {

    const [hideAdmin, setHideAdmin] = useState<boolean>((window.localStorage.getItem('admin-hide') === '1') || false);
    const [panelOpen, setPanelOpen] = useState<boolean>(((window.localStorage.getItem('admin-open') || '').length > 0) || false);
    const [tabIndex, setTabIndex] = useState<number>(Number(window.localStorage.getItem('admin-open') || 0));

    const panelStyle = { flex: 1, sx: { p: 2, overflow: 'hidden auto' }};

    window.localStorage.setItem('admin-hide', hideAdmin ? '1' : '0');
    window.localStorage.setItem('admin-open', panelOpen ? String(tabIndex) : '');
    setBodyClass('admin-hide', hideAdmin);

    return (
        <Box className={"admin-panel" + (panelOpen ? " open" : "")} sx={{ background: 'radial-gradient(#282828, #111)', color: '#eee', position: 'relative' }}>
            <Box className="admin-hide-opacity" sx={{ position: 'absolute', top: '4em', left: '0' }}>
                <Button 
                    sx={{ position: 'absolute', right: '10px', backgroundColor: '#333',  borderTopRightRadius: 0, borderBottomRightRadius: 0, minWidth: 0, p: 1 }} 
                    variant="contained"
                    onClick={() => { setPanelOpen(!panelOpen); }}
                >
                    <Wrench02Icon variant="solid" />
                </Button>
            </Box>
            <Box className="admin-panel-content" display="flex" flexDirection="column" sx={{ height: '100vh' }}>
                <Box sx={{ backgroundColor: 'rgba(255,255,255,0.08)', p: 2 }} display="flex" alignItems="center" justifyContent="space-around">
                    <Box>
                        User <Chip label={Api.user.userId} size="small" variant="outlined" sx={{ color: '#fff', ml: 1, px: 1 }} onClick={() => { clipboardSetText(Api.user.userId); }} />
                    </Box>
                    <Box>
                        Brand <Chip label={Api.user.brandId} size="small" variant="outlined" sx={{ color: '#fff', ml: 1, px: 1 }} onClick={() => { clipboardSetText(Api.user.brandId); }} />
                    </Box>
                    <Box>
                        <SwitchInput label="Hide" onChange={(e: any) => { setHideAdmin(e.target.checked); }} defaultChecked={hideAdmin} checked={hideAdmin} />
                    </Box>
                </Box>
                <Tabs value={tabIndex} sx={{ backgroundColor: 'rgba(0,0,0,0.3)' }}>
                    <Tab label="API" id="tab-0" aria-controls="tabpanel-0" onClick={() => { setTabIndex(0); }} />
                    <Tab label="Database" id="tab-1" aria-controls="tabpanel-1" onClick={() => { setTabIndex(1); }} />
                    <Tab label="Events" id="tab-2" aria-controls="tabpanel-2" onClick={() => { setTabIndex(2); }} />
                    <Tab label="Requests" id="tab-3" aria-controls="tabpanel-3" onClick={() => { setTabIndex(3); }} />
                    <Tab label="Localize" id="tab-4" aria-controls="tabpanel-4" onClick={() => { setTabIndex(4); }} />
                </Tabs>
                {(tabIndex === 0) ? (
                    <Box {...panelStyle}>
                        <AdminPanelAPI />
                    </Box>
                ) : (tabIndex === 1) ? (
                    <Box {...panelStyle}>
                        <AdminPanelDatabase />
                    </Box>
                ) : (tabIndex === 2) ? (
                    <Box {...panelStyle}>
                        <AdminPanelEvents />
                    </Box>
                ) : (tabIndex === 3) ? (
                    <Box {...panelStyle}>
                        <AdminPanelRequests />
                    </Box>
                ) : (tabIndex === 4) ? (
                    <Box {...panelStyle}>
                        <AdminPanelLocalize />
                    </Box>
                ) : null }
            </Box>
        </Box>
    )
}
