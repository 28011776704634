import { useEffect, useRef } from "react";

export function useLoad(callback: () => void) {
    const loaded = useRef(false);
    useEffect(() => {
        if (!loaded.current) {
            loaded.current = true;
            callback();
        }
    }, [callback, loaded])
}
