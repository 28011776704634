import { Box, Paper, Typography } from "@mui/material";
import { T } from "../../../Util/Localize";

export default function BusinessSettingsConfiguration() {
    return (
        <Box maxWidth="md" sx={{ mx: 'auto' }}>
            <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
                <Typography variant="h6" gutterBottom>
                    {T("Configuration")}
                </Typography>
                TODO
            </Paper>
        </Box>
    );
}
