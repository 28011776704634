import { Box, Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import { DashboardSquareAddIcon, LicenseIcon, Logout04Icon, Analytics01Icon, CreditCardIcon, ShoppingCart01Icon, SourceCodeIcon, QuestionIcon } from "@hugeicons/react";
import { T } from "../Util/Localize";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useEffect, useState } from "react";
import Api from "../Util/Api";

export default function NavPanel() {

    const nav = [
        { name: 'Dashboard', path: '/ux/Dashboard' },
        { name: 'Sites', path: '/ux/Sites' },
        { name: 'Clients', path: '/ux/Clients', userTypes: [1, 2, 3] },
        { name: 'Groups', path: '/ux/Groups', userTypes: [1, 2, 3] },
        { name: 'Businesses', path: '/ux/Businesses', userTypes: [1, 2] },
        { name: 'Business', path: '/ux/Business/', userTypes: [1, 2],
            items: [
                { name: 'Business Summary', path: '/ux/Business/Summary', icon: <DashboardSquareAddIcon size={20} /> },
                { name: 'Business Settings', path: '/ux/Business/Settings/', icon: <LicenseIcon size={20} />,
                    items: [
                        { name: 'Business Details', path: '/ux/Business/Settings/Details' },
                        { name: 'Configuration', path: '/ux/Business/Settings/Config' },
                        { name: 'Branding', path: '/ux/Business/Settings/Branding' },
                        { name: 'Email Messages', path: '/ux/Business/Settings/EmailMessages' },
                        { name: 'Domains', path: '/ux/Business/Settings/Domains' },
                        { name: 'Mobile App', path: '/ux/Business/Settings/MobileApp' },
                        { name: 'App Integrations', path: '/ux/Business/Settings/AppIntegrations' },
                        { name: 'Custom Designs', path: '/ux/Business/Settings/CustomDesigns' },
                        { name: 'Business Pages', path: '/ux/Business/Settings/Pages' },
                    ]
                },
                { name: 'Team Members', path: '/ux/Business/Team/', icon: <CreditCardIcon size={20} />,
                    items: [
                        { name: 'Team Members', path: '/ux/Business/Team/Members' },
                        { name: 'Pay Team', path: '/ux/Business/Team/PayTeam' },
                        { name: 'Permissions', path: '/ux/Business/Team/Permissions' },
                        { name: 'Market Areas', path: '/ux/Business/Team/MarketAreas' },
                        { name: 'Site Attribution', path: '/ux/Business/Team/SiteAttribution' },
                    ]
                },
                { name: 'Shopping Cart', path: '/ux/Business/Cart/', icon: <ShoppingCart01Icon size={20} />,
                    items: [
                        { name: 'Products', path: '/ux/Business/Cart/Products' },
                        { name: 'Service Areas', path: '/ux/Business/Cart/ServiceAreas' },
                        { name: 'Product Fees', path: '/ux/Business/Cart/ProductFees' },
                        { name: 'Sales Tax', path: '/ux/Business/Cart/SalesTax' },
                    ]},
                { name: 'Bulk Delivery', path: '/ux/Business/BulkDelivery', icon: <Logout04Icon size={20} /> },
                { name: 'Reports', path: '/ux/Business/Reports', icon: <Analytics01Icon size={20} /> },
                { name: 'Webhooks/APIs', path: '/ux/Business/WebhooksAPIs', icon: <SourceCodeIcon size={20} /> }
            ]
        },
        { name: 'Account', path: '/ux/Account',
            items: [
                { name: 'Basic Data', path: '/ux/Account/Basic', icon: <QuestionIcon size={20} /> },
                { name: 'Earnings', path: '/ux/Account/Earnings', icon: <QuestionIcon size={20} /> },
                { name: 'Listings Pages', path: '/ux/Account/ListingPages', icon: <QuestionIcon size={20} /> },
                { name: 'Reports', path: '/ux/Account/Reports', icon: <QuestionIcon size={20} /> },
                { name: 'Payment Types', path: '/ux/Account/PaymentTypes', icon: <QuestionIcon size={20} /> },
                { name: 'Update Password', path: '/ux/Account/Password', icon: <QuestionIcon size={20} /> },
                { name: 'App Integrations', path: '/ux/Account/AppIntegrations', icon: <QuestionIcon size={20} /> },
                { name: 'Service Areas', path: '/ux/Account/ServiceAreas', icon: <QuestionIcon size={20} /> },
            ]
         },
        { name: 'Admin', path: '/ux/Admin', userTypes: [1] },
    ]

    const [opens, setOpens] = useState<any>({});
    const path = useLocation().pathname;

    useEffect(() => {
        setOpens({});
    }, [path]);

    const filterMenu = (item: any) => {
        if (item.userTypes && !item.userTypes.includes(Api.user.userType)) { return false; }
        return true;
    }

    const toggleMenu = (path: string) => {
        setOpens({ ...opens, [path]: !opens[path] });
    }

    return (
        <List component="nav" disablePadding>
            {nav.filter(filterMenu).map((item: any, index: number) => {
                const forceOpen = path.startsWith(item.path);
                return (!item.items) ? (
                    <ListItem key={index} component={NavLink} to={item.path}>
                        {(item.icon) && (<ListItemIcon>{item.icon}</ListItemIcon>)}
                        <ListItemText primary={T(item.name)} />
                    </ListItem>
                ) : (
                    <>
                        <ListItemButton key={index} onClick={() => { if (!forceOpen) { toggleMenu(item.path); }}}>
                            {(item.icon) && (<ListItemIcon>{item.icon}</ListItemIcon>)}
                            {(forceOpen) ? (
                                <ListItemText primary={T(item.name)} primaryTypographyProps={{ fontWeight: 'bold' }} />
                            ) : (
                                <>
                                    <ListItemText primary={T(item.name)} />
                                    {(forceOpen || opens[item.path]) ? (<ExpandLess />) : (<ExpandMore />) }
                                </>
                            )}
                        </ListItemButton>
                        <Collapse in={forceOpen || opens[item.path]} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {item.items.map((subitem: any, subindex: number) => {
                                    const forceOpenSub = path.startsWith(subitem.path);
                                    return (!subitem.items) ? (
                                        <ListItem key={subindex} component={NavLink} to={subitem.path}>
                                            {(subitem.icon) && (<ListItemIcon>{subitem.icon}</ListItemIcon>)}
                                            <ListItemText primary={T(subitem.name)} />
                                        </ListItem>
                                    ) : (
                                        <>
                                            <ListItemButton key={subindex} onClick={() => { if (!forceOpenSub) { toggleMenu(subitem.path); }}}>
                                                {(subitem.icon) && (<ListItemIcon>{subitem.icon}</ListItemIcon>)}
                                                {(forceOpenSub) ? (
                                                    <ListItemText primary={T(subitem.name)} primaryTypographyProps={{ fontWeight: 'bold' }} />
                                                ) : (
                                                    <>
                                                        <ListItemText primary={T(subitem.name)} />
                                                        {(forceOpenSub || opens[subitem.path]) ? (<ExpandLess />) : (<ExpandMore />) }
                                                    </>
                                                )}
                                            </ListItemButton>
                                            <Collapse in={forceOpenSub || opens[subitem.path]} timeout="auto" unmountOnExit>
                                                <Box display="flex">
                                                    <Box width="2px" sx={{ bgcolor: 'divider', ml: 3.25, mr: 2 }}></Box>
                                                    <Box flex={1}>
                                                        <List component="div" disablePadding>
                                                            {subitem.items.map((subsubitem: any, subsubindex: number) => {
                                                                return (
                                                                    <ListItem key={subsubindex} component={NavLink} to={subsubitem.path}>
                                                                        {(subsubitem.icon) && (<ListItemIcon>{subsubitem.icon}</ListItemIcon>)}
                                                                        <ListItemText primary={T(subsubitem.name)} />
                                                                    </ListItem>
                                                                );
                                                            })}
                                                        </List>
                                                    </Box>
                                                </Box>
                                            </Collapse>
                                        </>
                                    )
                                })}
                            </List>
                        </Collapse>
                    </>
                );
            })}
        </List>
    );
}
