import { useEffect, useState } from "react";
import { Box, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import Api from "../../Util/Api";

export default function AdminPanelDatabase() {

    const [databaseCalls, setDatabaseCalls] = useState(Api.databaseLog);

    useEffect(() => {
        Api.databaseNotify = (list: any) => { setDatabaseCalls([...list]); }
        return () => { Api.databaseNotify = null; }
    }, []);

    return (databaseCalls.length === 0) ? (
        <Box>No Database calls</Box>
    ) : (
        <Table size="small">
            <TableBody>
                <TableRow><TableCell></TableCell></TableRow>
                {databaseCalls.map((call: any, index: number) => (
                    <TableRow key={index}>
                        <TableCell>
                            <Box display="flex">
                                <Typography flex={1} color="#8fc" variant="caption">{call.page}</Typography>
                                <Typography flex={1} color="#fc8" variant="caption">{call.url}</Typography>
                                <Typography color="#8cf" variant="caption">{(call.duration < 1000) ? call.duration + "ms" : Number(call.duration / 1000).toFixed(call.duration > 100000 ? 0 : call.duration > 10000 ? 1 : 2) + "s"}</Typography>
                            </Box>
                            {call.sql}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}
