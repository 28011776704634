import { Box, Typography } from "@mui/material";
import { T } from "../../Util/Localize";
import ActionButtons from "../../Components/input/ActionButtons";
import { useEffect, useState } from "react";
import { FormData, emptyFormData, errorData } from "../../Util/FormUtil";
import TextInput from "../../Components/input/TextInput";
import Api from "../../Util/Api";
import { useLoad } from "../../Util/Hooks";
import SwitchInput from "../../Components/input/SwitchInput";
import { enqueueSnackbar } from "notistack";

type PolicyDetailsProps = {
    policyId: number | null;
    setPolicyId: any;
    policyUpdate: any;
}

export default function PolicyDetails({ policyId, setPolicyId, policyUpdate }: PolicyDetailsProps) {

    const [data, setData] = useState<any>(null);
    const [policy, setPolicy] = useState<FormData>(emptyFormData());
    const isLoading = (data === null);

    useLoad(() => {
        if (policyId) {
            Api.get('policy/' + policyId).then((r: any) => {
                if (r.ok) { 
                    setData(r.data);
                }
                else {
                    enqueueSnackbar('Unable to load policy', { variant: 'error' });
                }
            });
        }
        else {
            setData({ title: '', content: '', note: '', status: 0 });
        }
    });

    const saveData = async (updates: any, setUpdates: any) => {
        let update;
        if (policyId) {
            update = await Api.patch('policy/' + policyId, updates);
        }
        else {
            update = await Api.put('policy', {...{ title: '', content: '', note: '', status: 0 }, ...updates});
            if (update.ok) {
                updates.pid = update.data.pid;
                updates.updated = (new Date());
            }
        }
        if (update.ok) { 
            setData((prev: any) => { 
                const newData = { ...prev, ...updates };
                policyUpdate(newData);
                return (newData);
            }); 
            setPolicyId(null);
        }
        else {
            errorData(update, setUpdates);
            enqueueSnackbar('Unable to update policy', { variant: 'error' });
        }
    }

    useEffect(() => {
        if (policy.cancel) {
            setPolicyId(null);
        }
    }, [policy.cancel, setPolicyId]);

    return (
        <Box className="panel-medium" display="flex" flexDirection="column">
            <Box flex={1} display="flex" flexDirection="column" sx={{ overflow: 'auto', px: 4, pt: 3 }}>
                <Typography variant="h5" gutterBottom>
                    {T((policyId ? "Update" : "Create") + " Team Member Agreement")}
                </Typography>
                <TextInput
                    name="title"
                    label={T("Agreement Name")}
                    data={data}
                    state={[policy, setPolicy]}
                    isLoading={isLoading}
                />
                <SwitchInput
                    name="status"
                    label={T("Active")}
                    data={data}
                    state={[policy, setPolicy]}
                    isLoading={isLoading}
                    true={1}
                    false={0}
                />
                <Box flex={1} className="fill-height">
                    <TextInput
                        name="content"
                        label={T("Agreement/Policy Content")}
                        data={data}
                        state={[policy, setPolicy]}
                        isLoading={isLoading}
                        multiline
                        minRows={2}
                        maxLength={100000}
                        inputProps={{ style: { minHeight: '5rem' } }}
                    />
                </Box>
                <TextInput
                    name="note"
                    label={T("Internal Notes (optional)")}
                    data={data}
                    state={[policy, setPolicy]}
                    isLoading={isLoading}
                    multiline
                    maxLength={1000}
                    minRows={2}
                    maxRows={5}
                />
            </Box>
            <Box sx={{ px: 4, pb: 3 }}>
                <ActionButtons active
                    onSave={saveData} 
                    state={[policy, setPolicy]}
                />
            </Box>
        </Box>
    );
}
