import { Breadcrumbs, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { titleCase } from "../Util/Functions";
import { T } from "../Util/Localize";

interface Dictionary {
    [key: string]: string;
}

const namelib: Dictionary = {
    "/ux": "Dashboard",
    "/ux/business/summary": "Business Summary",
    "/ux/business/details": "Business Details",
    "/ux/business/settings": "Business Settings",
    "/ux/business/settings/details": "Business Details",
    "/ux/business/settings/config": "Configuration",
    "/ux/business/settings/branding": "Branding",
    "/ux/business/settings/emailmessages": "Email Messages",
    "/ux/business/settings/mobileapp": "Mobile App",
    "/ux/business/settings/appintegrations": "App Integrations",
    "/ux/business/settings/customdesigns": "Custom Designs",
    "/ux/business/settings/pages": "Business Pages",
    "/ux/business/team": "Team Members",
    "/ux/business/team/members": "Team Members",
    "/ux/business/team/payteam": "Pay Team",
    "/ux/business/team/marketareas": "Market Areas",
    "/ux/business/shoppingcart": "Shopping Cart",
    "/ux/business/cart/salestax": "Sales Tax",
    "/ux/business/productfees": "Product Fees",
    "/ux/business/serviceareas": "Service Areas",
    "/ux/business/siteattribution": "Site Attribution",
    "/ux/business/bulkdelivery": "Bulk Delivery",
    "/ux/business/webhooksapis": "Webhooks & APIs",
    "/ux/account/basic": "Basic Data",
    "/ux/account/listingpages": "Listings Pages",
    "/ux/account/paymenttypes": "Payment Types",
    "/ux/account/password": "Update Password",
    "/ux/account/appintegrations": "App Integrations",
    "/ux/account/serviceareas": "Service Areas",
}

export default function BreadcrumbsComponent() {

    const location = useLocation();
    const paths = location.pathname.substring(1).toLowerCase().split("/");
    let url = '';

    return ((paths.length > 1) && paths[1] !== 'dashboard') ? (
        <Breadcrumbs aria-label="breadcrumb">
            {
                paths.map((path: string, index: number) => {
                    url += '/' + path;
                    return (index < paths.length - 1) ? (
                        <Link to={url} key={index}>
                            <Typography variant="body2">{T(namelib[url] || titleCase(path))}</Typography>
                        </Link>
                    ) : (
                        <Typography variant="body2" key={index}>{T(namelib[url] || titleCase(path))}</Typography>
                    )
                })
            }
        </Breadcrumbs>
    ) : null
}
