import { useState } from "react";
import Api from "../../../Util/Api";
import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import { T } from "../../../Util/Localize";
import { FormData, emptyFormData } from "../../../Util/FormUtil";
import TextInput from "../../../Components/input/TextInput";
import ActionButtons from "../../../Components/input/ActionButtons";
import { useLoad } from "../../../Util/Hooks";
import NoAccess from "../../../Components/NoAccess";

export default function BusinessSettingsBranding() {
    
    const [data, setData] = useState<any>({});
    const [details, setDetails] = useState<FormData>(emptyFormData());

    useLoad(() => {
        Api.get('api/brand').then((r: any) => {
            if (r.ok) { setData(r.data); }
        });
    });

    const saveData = async (updates: any, setUpdates: any) => {
        console.log('save data', updates)
    }

    // console.log('data', data);

    return (Api.user.userType > 2) ? (
        <NoAccess />
    ) : (data === null) ? (
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100%', width: '100%' }}>
            <CircularProgress color="inherit" size={32} sx={{ opacity: 0.25 }} />
        </Box>
    ) : (
        <Box>
            <Box maxWidth="md" sx={{ mx: 'auto' }}>
                <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
                    <Typography variant="h6" gutterBottom>
                        {T("Branding")}
                    </Typography>
                    <TextInput
                        name="name"
                        label={T("Headline Font")}
                        defaultValue={null}
                        state={[details, setDetails]}
                    />
                    <TextInput
                        name="phone"
                        label={T("Font Size")}
                        defaultValue={null}
                        state={[details, setDetails]}
                        inputProps={{ maxLength: 32 }}
                    />
                    <TextInput
                        name="email"
                        label={T("Body Font")}
                        defaultValue={null}
                        state={[details, setDetails]}
                    />
                    <ActionButtons 
                        onSave={saveData} 
                        state={[details, setDetails]} 
                    />
                </Paper>
            </Box>
        </Box>
    );
};
