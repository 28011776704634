import { useOutletContext } from 'react-router-dom';

export default function LegacyPage(props: { title: string, src: string }) {
    
    let { title, src } = props;
    const oprops: any = useOutletContext();
    const isMobile = oprops.isMobile || false;
    src = src + ((src.indexOf('?') > -1) ? '&' : '?') + 'new_site_embedded=1';

    return (
        <iframe title={title} src={src} style={{ border: 0, verticalAlign: 'middle', background: 'transparent', position: 'absolute', top: (isMobile ? 0 : 48), left: 0, right: 0, bottom: 0, width: '100%', height: (isMobile ? '100%' : 'calc(100% - 48px)') }} />
    );
}
