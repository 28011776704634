
export type FormData = { 
    cancel: boolean, 
    modified: boolean, 
    saving: boolean, 
    data: any, 
    error?: any 
};

export function cancelData(f: any) {
    f((prev: any) => {
        prev.cancel = true;
        return {...prev};
    });
}

export function emptyFormData(): FormData {
    return { cancel: false, modified: false, saving: false, data: {} };
}

export function errorData(response: any, setData: any) {
    setData((prev: any) => { return {...prev, error: response.errors || [], saving: false }; })
}

export function saveData(setData: any, onSave: any) {
    setData((prev: any) => { 
        if (prev.saving) return prev;
        prev.saving = true;
        onSave(prev.data, setData);
        return {...prev};
    });
}
