import { useEffect, useState } from "react";
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import Api from "../../Util/Api";

export default function AdminPanelAPI() {

    const [apiCalls, setApiCalls] = useState(Api.apiLog);

    useEffect(() => {
        Api.apiNotify = (list: any) => { setApiCalls([...list]); }
        return () => { Api.apiNotify = null; }
    }, []);

    return (apiCalls.length === 0) ? (
        <Box>No API calls</Box>
    ) : (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>Page</TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>Method</TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>Endpoint</TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>Status</TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>Duration</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {apiCalls.map((call: any, index: number) => (
                    <TableRow key={index}>
                        <TableCell><Typography color="#8fc" variant="caption" className="truncate-front" title={call.page} width="100%" maxWidth="150px">{call.page}</Typography></TableCell>
                        <TableCell>{call.method}</TableCell>
                        <TableCell><Typography  color="#fc8" variant="caption" className="truncate" title={call.url} width="100%" maxWidth="150px">{call.url}</Typography></TableCell>
                        <TableCell>{call.status}</TableCell>
                        <TableCell><Typography  color="#8cf" variant="caption">{(call.duration < 1000) ? call.duration + "ms" : Number(call.duration / 1000).toFixed(call.duration > 100000 ? 0 : call.duration > 10000 ? 1 : 2) + "s"}</Typography></TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}
